import React from 'react';
import { actions } from "real_wear_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer,translate } from 'react-i18next';
import qdmstyles  from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import './index.css';
import { languageOptions } from '../../utils'
import { LoggerContext } from '../../contexts'

import { Success } from "../../components"


import * as ALL from '../../components'

import { 
TextInput, 
Dropdown } from 'qdm-component-library';

class PatientRegistration extends React.Component{
        static contextType = LoggerContext
        constructor(props,context){
            super(props,context);
            this.state = {patient_registration_lj4kk  : null,patient_registration_ec1wv  : null,patient_registration_qlm9w  : null,patient_registration_ukwmz  : null,patient_registration_2njp6  : null,patient_registration_qvcov  : null,patient_registration_eqt7z  : null,patient_registration_qdxhq  : null}
        }

        changeState = (key, value) => {
            this.setState({
                [key]:value
            });
        }
        onchange_lj4kk  = (e) => {
                    
                    this.setState({ patient_registration_lj4kk : e?.target?.value })
                }
onchange_ec1wv  = (e) => {
                    
                    this.setState({ patient_registration_ec1wv : e?.target?.value })
                }
onchange_qlm9w  = (e) => {
                    
                    this.setState({ patient_registration_qlm9w : e?.target?.value })
                }
onchange_ukwmz  = (e) => {
                    
                    this.setState({ patient_registration_ukwmz : e?.target?.value })
                }
onchange_2njp6  = (e) => {
                    
                    this.setState({ patient_registration_2njp6 : e })
                }
onchange_qvcov  = (e) => {
                    
                    this.setState({ patient_registration_qvcov : e?.target?.value })
                }
onchange_eqt7z  = (e) => {
                    
                    this.setState({ patient_registration_eqt7z : e })
                }
onclick_qdxhq  =  async (event,data) => {const payload0 = {

                                            patient_registration_eqt7z  :  this.state.patient_registration_eqt7z ?.label,

                                            patient_registration_ec1wv  :  this.state.patient_registration_ec1wv ,

                                            patient_registration_ukwmz  :  this.state.patient_registration_ukwmz ,

                                            patient_registration_lj4kk  :  this.state.patient_registration_lj4kk ,

                                            patient_registration_qlm9w  :  this.state.patient_registration_qlm9w ,

                                            patient_registration_2njp6  :  this.state.patient_registration_2njp6 ?.value,

                                            patient_registration_qvcov  :  this.state.patient_registration_qvcov ,history : this.props.history.location.state,data : data}
let data0 = await this.props.Patient_RegistrationPatient_Insert_Query_QdXHq(payload0)
if(data0?.payload?.Code === 200){
                
                  this.setState({ patient_registration_qdxhq_9sv6l: true })
                
            }
                  
                
                }
        componentDidMount(){

             /// sample localstorage translation
             if(sessionStorage.defaultLng){
                const {i18n} = this.props;
                i18n.changeLanguage(sessionStorage.defaultLng)
            }
            
            
        }

        shouldComponentUpdate(props, state) {
            
            return true;
          }


        render(){

            

            

            return <NamespacesConsumer>
                {(t, { i18n }) => <React.Fragment    >
                    <div key={"0"}       >
<input key={"0"}     type ={"hidden"}  id ={"anPageName"}    value ={"patient-registration"}    >
</input>
 <div key={"1"}     class ={"patient-registration screen"}    >
<div key={"0"}     class ={"group-93722-zVTIDx"}    >
<div key={"0"}     class ={"rectangle-47148-txmB1b"}    >
</div>
 <div key={"1"}     class ={"qdm-textinput-txmB1b"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_lj4kk (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Patient Name")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}   style={ qdmstyles.LJKK } >
</TextInput>
</div>
 <div key={"2"}     class ={"qdm-textinput-mReW0w"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_qvcov (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Remark")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
 <div key={"3"}     class ={"qdm-dropdown-txmB1b"}    >
<Dropdown key={"0"} onChange = {(e) => this.onchange_2njp6 (e) }    elevation ={"1"}  fullWidth ={true}  dropDownTextSize ={"14"}  dropDownTextColor ={"#000"}  activeTextColor ={"#000"}  dropdownListMargin ={"0"}  dropdownListPadding ={"8"}  options ={[{"value":"Chennai","label":"Chennai"},{"value":"Coimbatore","label":"Coimbatore"},{"value":"Madurai","label":"Madurai"},{"value":"Trichy","label":"Trichy"}]}  label= {t("Facility Name")}  isRequired ={true}  Url ={""}  keyName ={""}  valueName ={""}  placeholder ={"Search Location"}    >
</Dropdown>
</div>
 <div key={"4"}     class ={"qdm-textinput-YOgISv"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_qlm9w (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("EMP No")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
 <div key={"5"}     class ={"qdm-textinput-ILayjh"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_ukwmz (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Mobile Number")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
 <div key={"6"}     class ={"component-93700-txmB1b"}    >
<div key={"0"}     class ={"qdm-textinput-5VfWu1"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_ec1wv (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("ID Card")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
 <div key={"1"}     class ={"qdm-dropdown-5VfWu1"}    >
<Dropdown key={"0"} onChange = {(e) => this.onchange_eqt7z (e) }    elevation ={"1"}  fullWidth ={true}  dropDownTextSize ={"14"}  dropDownTextColor ={"#000"}  activeTextColor ={"#000"}  dropdownListMargin ={"0"}  dropdownListPadding ={"8"}  options ={[{"value":"Passport","label":"Passport"},{"value":"NRIC","label":"NRIC"}]}  label= {t("ID Type")}  isRequired ={false}  Url ={""}  keyName ={""}  valueName ={""}   style={ qdmstyles.EqTZ } >
</Dropdown>
</div>
</div>
 <div key={"7"}     class ={"qdm-button-txmB1b"}    >
<div key={"0"} onClick = {(e) => this.onclick_qdxhq (e) }    class ={"submit-J1HMpe"}      >
Submit</div>
<Modal open={Boolean(this.state?.patient_registration_qdxhq_9sv6l ===true)}  onClose={() => this.setState({patient_registration_qdxhq_9sv6l  : null})} >
        <Success />
        </Modal></div>
 <div key={"8"}     class ={"cancel-button-txmB1b"}    >
<div key={"0"}     class ={"rectangle-47166-jELxVt"}    >
</div>
 <div key={"1"}     class ={"cancel-jELxVt"}      >
Cancel</div>
</div>
 <div key={"9"}     class ={"patient-registration-txmB1b"}      >
Patient Registration</div>
</div>
 <img key={"1"}     class ={"path-93228-zVTIDx"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744560"}  alt ={"path-93228@1x.png"}    >
</img>
 <div key={"2"}     class ={"group-93721-zVTIDx"}    >
<div key={"0"}     class ={"rectangle-47180-C1tNJq"}    >
</div>
 <div key={"1"}     class ={"login-avatar-C1tNJq"}    >
<div key={"0"}     class ={"rectangle-47177-QIQaxl"}    >
</div>
 <img key={"1"}     class ={"rectangle-47178-QIQaxl"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744508"}  alt ={"rectangle-47178@1x.png"}    >
</img>
 <div key={"2"}     class ={"rakin-QIQaxl"}      >
RAKIN</div>
 <img key={"3"}     class ={"icon-awesome-chevron-down-QIQaxl"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744504"}  alt ={"icon-awesome-chevron-down@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"patient-registration-C1tNJq"}      >
Patient Registration</div>
</div>
 <div key={"3"}     class ={"group-93720-zVTIDx"}    >
<div key={"0"}     class ={"rectangle-47173-LirMrN"}    >
</div>
 <div key={"1"}     class ={"group-93719-LirMrN"}    >
<div key={"0"}     class ={"logo-oSnKxZ"}    >
<img key={"0"}     class ={"x51-client_profile_subscription-10-wYxAZU"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183739820"}  alt ={"5-1-client-profile-subscription---10@1x.png"}    >
</img>
 <div key={"1"}  
            onClick={() => { 
                
                this.props.history.push("/login")
            }}
           class ={"global-doctors-wYxAZU"}    >
<span key={"0"}     class ={"span0-2KEHE5"}      >
Global</span>
 <span key={"1"}     class ={"span1-2KEHE5"}      >
Doctors</span>
</div>
</div>
 <div key={"1"}     class ={"rectangle-47179-oSnKxZ"}    >
</div>
 <div key={"2"}     class ={"version-oSnKxZ"}    >
<div key={"0"}     class ={"v1001-hO8D8V"}      >
v1.0.01</div>
</div>
 <div key={"3"}     class ={"login-details-oSnKxZ"}    >
<div key={"0"}     class ={"hello-IgMki6"}      >
Hello </div>
 <div key={"1"}     class ={"mr-rakin-IgMki6"}      >
Mr.Rakin</div>
 <div key={"2"}     class ={"sathishrakinainqacom-IgMki6"}      >
Sathish.rakin@ainqa.com</div>
 <img key={"3"}     class ={"line-9-IgMki6"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744502"}  alt ={"line-9@1x.png"}    >
</img>
</div>
 <div key={"4"}     class ={"reports-oSnKxZ"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/reports")
            }}
           class ={"reports-74itnq roboto-medium-dove-gray-14px"}      >
Reports</div>
 <img key={"1"}     class ={"download-7-74itnq"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744506"}  alt ={"download--7-@1x.png"}    >
</img>
</div>
 <div key={"5"}     class ={"patient-regn-oSnKxZ"}    >
<div key={"0"}     class ={"patient-registration-NmGpcR"}      >
Patient Registration</div>
 <img key={"1"}     class ={"rectangle-47175-NmGpcR"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744525"}  alt ={"rectangle-47175@1x.png"}    >
</img>
</div>
 <div key={"6"}     class ={"master-oSnKxZ"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/specialist_doctor")
            }}
           class ={"master-fgeYR1 roboto-medium-dove-gray-14px"}      >
Master</div>
 <img key={"1"}     class ={"material-master-data-fgeYR1"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744545"}  alt ={"material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"}    >
</img>
</div>
 <div key={"7"}     class ={"visit-regn-oSnKxZ"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/visit_registration")
            }}
           class ={"visit-registration-PhuJQ4 roboto-medium-dove-gray-14px"}      >
Visit Registration</div>
 <img key={"1"}     class ={"download-6-PhuJQ4"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744562"}  alt ={"download--6-@1x.png"}    >
</img>
</div>
</div>
</div>
</div>
</div>

                </React.Fragment>}
            </NamespacesConsumer>
        }
} 

const mapStateToProps = (state) => ({
    
});
  
export default connect(
    mapStateToProps,
    actions
)(withRouter(translate()(PatientRegistration)));