import React from 'react';
import { actions } from "real_wear_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer,translate } from 'react-i18next';
import qdmstyles  from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import './index.css';
import { languageOptions } from '../../utils'
import { LoggerContext } from '../../contexts'

import { Qrcode, Success } from "../../components"


import * as ALL from '../../components'

import { 
div, 
Dropdown, 
TextInput } from 'qdm-component-library';

class VisitRegistration extends React.Component{
        static contextType = LoggerContext
        constructor(props,context){
            super(props,context);
            this.state = {visit_registration_zopet  : null,visit_registration_vgpos  : null,visit_registration_0zujx  : null,visit_registration_hzkpz  : null}
        }

        changeState = (key, value) => {
            this.setState({
                [key]:value
            });
        }
        onchange_zopet  = (e) => {
                    
                    this.setState({ visit_registration_zopet : e?.target?.value })
                }
onclick_vgpos  =  async (event,data) => {const payload0 = {

                                            visit_registration_zopet  :  this.state.visit_registration_zopet ,history : this.props.history.location.state,data : data}
let data0 = await this.props.Visit_RegistrationPatient_Search_vgpoS(payload0)
                  
                
                }
onclick_0zujx  =  async (event,data) => {const payload0 = {

                                            visit_registration_hzkpz  :  this.state.visit_registration_hzkpz ?.value,history : this.props.history.location.state,data : data}
let data0 = await this.props.Visit_RegistrationEncounter_Insert_0ZUjx(payload0);
const payload1 = {history : this.props.history.location.state,data : data}
let data1 = await this.props.Visit_Registrationavc_meet_0ZUjx(payload1)
if(data0?.payload?.Code === 200){
                
                  this.setState({ visit_registration_0zujx_osqne: true })
                
            }
                  
                
                }
onchange_hzkpz  = (e) => {
                    
                    this.setState({ visit_registration_hzkpz : e })
                }
        componentDidMount(){

             /// sample localstorage translation
             if(sessionStorage.defaultLng){
                const {i18n} = this.props;
                i18n.changeLanguage(sessionStorage.defaultLng)
            }
            
            
        }

        shouldComponentUpdate(props, state) {
            
        if(this.props?.Encounter_InsertData?.loading !== props?.Encounter_InsertData?.loading){
            if(!props?.Encounter_InsertData?.loading && props?.Encounter_InsertData.error){
                
            }else if(!props?.Encounter_InsertData?.loading && !props?.Encounter_InsertData.error){
                
        }
    }
        
            return true;
          }


        render(){

            
            const { PatientData,Patient_SearchData,Encounter_InsertData ,data } = this.props;
            

            

            return <NamespacesConsumer>
                {(t, { i18n }) => <React.Fragment    >
                    <div key={"0"}       >
<input key={"0"}     type ={"hidden"}  id ={"anPageName"}    value ={"visit-registration"}    >
</input>
 <div key={"1"}     class ={"visit-registration screen"}    >
<div key={"0"}     class ={"qdm-list-JQaIUO"}     style={ qdmstyles.wktR } >
{Array.isArray(Patient_SearchData?.['result']) && Patient_SearchData?.['result']?.map((data , index)=>{
                      return <React.Fragment key={index}><div key={"0"}     class ={"listitem-Ci3S5d"}    >
<div key={"0"}     class ={"bg-jWfJzK"}    >
<div key={"0"}     class ={"rectangle-32-Zxtv05"}   style={ qdmstyles.XNSfv } >
</div>
 <div key={"1"}     class ={"group-93710-Zxtv05"}    >
<div key={"0"}     class ={"patient-name-vGUBia roboto-normal-concord-12px"}      >
Patient name</div>
 <div key={"1"}     class ={"administrator-vGUBia roboto-normal-black-12px"}      >
{data?.Patient_Name}</div>
 <div key={"2"}     class ={"x1234567890-vGUBia roboto-normal-black-12px"}      >
{data?.Mobile_Number}</div>
 <div key={"3"}     class ={"dfhs5-d7-vGUBia roboto-normal-black-12px"}      >
{data?.Patient_ID_Number}</div>
 <div key={"4"}     class ={"x654343-vGUBia roboto-normal-black-12px"}      >
{data?.Emp_Number}</div>
 <div key={"5"}     class ={"malaysia-vGUBia roboto-normal-black-12px"}      >
{data?.Facility_ID}</div>
 <div key={"6"}     class ={"x54637289-vGUBia roboto-normal-black-12px"}      >
54637289</div>
 <div key={"7"}     class ={"mobile-no-vGUBia roboto-normal-concord-12px"}      >
Mobile No</div>
 <div key={"8"}     class ={"id-card-vGUBia roboto-normal-concord-12px"}      >
ID Card</div>
 <div key={"9"}     class ={"emp-no-vGUBia roboto-normal-concord-12px"}      >
EMP No</div>
 <div key={"10"}     class ={"facility-vGUBia roboto-normal-concord-12px"}      >
Facility</div>
 <div key={"11"}     class ={"mrn-vGUBia roboto-normal-concord-12px"}      >
MRN</div>
 <div key={"12"}     class ={"x-vGUBia roboto-normal-sonic-silver-12px"}      >
:</div>
 <div key={"13"}     class ={"x-YItia6 roboto-normal-sonic-silver-12px"}      >
:</div>
 <div key={"14"}     class ={"x-BQivjw roboto-normal-sonic-silver-12px"}      >
:</div>
 <div key={"15"}     class ={"x-I3fRhZ roboto-normal-sonic-silver-12px"}      >
:</div>
 <div key={"16"}     class ={"x-DdQTBb roboto-normal-sonic-silver-12px"}      >
:</div>
 <div key={"17"}     class ={"x-UxjeUw roboto-normal-sonic-silver-12px"}      >
:</div>
</div>
 <img key={"2"}     class ={"line-5-Zxtv05"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183753902"}  alt ={"line-5@1x.png"}    >
</img>
 <div key={"3"}     class ={"search-result-Zxtv05"}      >
Search Result:</div>
</div>
 <div key={"1"}     class ={"component-93700-jWfJzK"}    >
<div key={"0"}     class ={"qdm-dropdown-cshRPh"}   style={ qdmstyles.SkWp } >
<Dropdown key={"0"} onChange = {(e) => this.onchange_hzkpz (e) }    elevation ={"1"}  fullWidth ={true}  dropDownTextSize ={"14"}  dropDownTextColor ={"#000"}  activeTextColor ={"#000"}  dropdownListMargin ={"0"}  dropdownListPadding ={"8"}  options ={[{"value":"Dr.Watamaniuk","label":"Dr.Watamaniuk"}]}  label= {t("Doctor details")}  isRequired ={true}  Url ={"https://61db01b44593510017aff76e.mockapi.io/doctorslist"}  keyName ={"name"}  valueName ={"id"}   style={ qdmstyles.hzKpZ } >
</Dropdown>
</div>
</div>
 <div key={"2"}     class ={"qdm-button-jWfJzK"}    >
<div key={"0"} onClick = {(e) => this.onclick_0zujx (e , data ) }    class ={"visit-a3YOxN"}     style={ qdmstyles.ZUjx } >
Visit</div>
<Modal open={Boolean(this.state?.visit_registration_0zujx_osqne ===true)}  onClose={() => this.setState({visit_registration_0zujx_osqne  : null})} >
        <Qrcode />
        </Modal></div>
 <img key={"3"}     class ={"close-icon-jWfJzK"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183753889"}  alt ={"close-icon@1x.png"}    >
</img>
 <img key={"4"}     class ={"path-93229-jWfJzK"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183753912"}  alt ={"path-93229@1x.png"}    >
</img>
</div>
</React.Fragment>
                  })}{data?.result}</div>
 <div key={"1"}     class ={"group-93721-JQaIUO"}    >
<div key={"0"}     class ={"rectangle-47180-sXrDpa"}    >
</div>
 <div key={"1"}     class ={"login-avatar-sXrDpa"}    >
<div key={"0"}     class ={"rectangle-47177-8Kn8uO"}    >
</div>
 <img key={"1"}     class ={"rectangle-47178-8Kn8uO"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744508"}  alt ={"rectangle-47178@1x.png"}    >
</img>
 <div key={"2"}     class ={"rakin-8Kn8uO"}      >
RAKIN</div>
 <img key={"3"}     class ={"icon-awesome-chevron-down-8Kn8uO"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744504"}  alt ={"icon-awesome-chevron-down@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"visit-registration-sXrDpa"}      >
Visit Registration</div>
</div>
 <div key={"2"}     class ={"group-93720-JQaIUO"}    >
<div key={"0"}     class ={"rectangle-47173-Io4y1R"}    >
</div>
 <div key={"1"}     class ={"group-93719-Io4y1R"}    >
<div key={"0"}     class ={"logo-01bKn4"}    >
<img key={"0"}     class ={"x51-client_profile_subscription-10-inMWm9"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183739820"}  alt ={"5-1-client-profile-subscription---10@1x.png"}    >
</img>
 <div key={"1"}     class ={"global-doctors-inMWm9"}    >
<span key={"0"}     class ={"span0-PYDfvh"}      >
Global</span>
 <span key={"1"}     class ={"span1-PYDfvh"}      >
Doctors</span>
</div>
</div>
 <div key={"1"}     class ={"version-01bKn4"}    >
<div key={"0"}     class ={"v1001-mjI7WH"}      >
v1.0.01</div>
</div>
 <div key={"2"}     class ={"login-details-01bKn4"}    >
<div key={"0"}     class ={"hello-CP9rEC"}      >
Hello </div>
 <div key={"1"}     class ={"mr-rakin-CP9rEC"}      >
Mr.Rakin</div>
 <div key={"2"}     class ={"sathishrakinainqacom-CP9rEC"}      >
Sathish.rakin@ainqa.com</div>
 <img key={"3"}     class ={"line-9-CP9rEC"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744502"}  alt ={"line-9@1x.png"}    >
</img>
</div>
 <div key={"3"}     class ={"reports-01bKn4"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/reports")
            }}
           class ={"reports-5zPeTW roboto-medium-dove-gray-14px"}      >
Reports</div>
 <img key={"1"}     class ={"download-7-5zPeTW"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744506"}  alt ={"download--7-@1x.png"}    >
</img>
</div>
 <div key={"4"}     class ={"patient-regn-01bKn4"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/patient_registration")
            }}
           class ={"patient-registration-2aw2Za roboto-medium-dove-gray-14px"}      >
Patient Registration</div>
 <img key={"1"}     class ={"rectangle-47175-2aw2Za"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744525"}  alt ={"rectangle-47175@1x.png"}    >
</img>
</div>
 <div key={"5"}     class ={"master-01bKn4"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/specialist_doctor")
            }}
           class ={"master-HNJFiO roboto-medium-dove-gray-14px"}      >
Master</div>
 <img key={"1"}     class ={"material-master-data-HNJFiO"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744545"}  alt ={"material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"}    >
</img>
</div>
 <div key={"6"}     class ={"visit-regn-01bKn4"}    >
<div key={"0"}     class ={"rectangle-47179-khzOsH"}    >
</div>
 <div key={"1"}     class ={"visit-registration-khzOsH"}      >
Visit Registration</div>
 <img key={"2"}     class ={"download-6-khzOsH"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744562"}  alt ={"download--6-@1x.png"}    >
</img>
</div>
</div>
</div>
 <div key={"3"}     class ={"group-93707-JQaIUO"}   style={ qdmstyles.BZhQD } >
<div key={"0"}     class ={"qdm-button-E62kL9"}    >
<div key={"0"} onClick = {(e) => this.onclick_vgpos (e , data ) }    class ={"search-6vogkz"}     style={ qdmstyles.vgpoS } >
Search</div>
</div>
 <div key={"1"}     class ={"qdm-textinput-E62kL9"}   style={ qdmstyles.kWle } >
<TextInput key={"0"} onChange = {(e) => this.onchange_zopet (e) }    type ={"text"}  name= {t("Patient Search")}  variant ={"none"}  helperTextType ={"error"}  label= {t("Patient Search")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={"Search....."}  size ={"medium"}  isRequired ={false}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}   style={ qdmstyles.zoPet } >
</TextInput>
</div>
</div>
</div>
</div>

                </React.Fragment>}
            </NamespacesConsumer>
        }
} 

const mapStateToProps = (state) => ({
    Patient_SearchData:state?.Visit_RegistrationPatient_Search_vgpoSReducer?.Patient_Search_vgpoS?.data,Encounter_InsertData:state?.Visit_RegistrationEncounter_Insert_0ZUjxReducer?.Encounter_Insert_0ZUjx?.data,
});
  
export default connect(
    mapStateToProps,
    actions
)(withRouter(translate()(VisitRegistration)));