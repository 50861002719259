import React from 'react';
import { actions } from "real_wear_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer,translate } from 'react-i18next';
import qdmstyles  from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import './index.css';
import { languageOptions } from '../../utils'
import { LoggerContext } from '../../contexts'




import * as ALL from '../../components'

import { 
Container, 
Row, 
QRscan } from 'qdm-component-library';

class Qrcode extends React.Component{
        static contextType = LoggerContext
        constructor(props,context){
            super(props,context);
            this.state = {}
        }

        changeState = (key, value) => {
            this.setState({
                [key]:value
            });
        }
        
        componentDidMount(){

             /// sample localstorage translation
             if(sessionStorage.defaultLng){
                const {i18n} = this.props;
                i18n.changeLanguage(sessionStorage.defaultLng)
            }
            
            
        }

        shouldComponentUpdate(props, state) {
            
            return true;
          }


        render(){

            

            

            return <NamespacesConsumer>
                {(t, { i18n }) => <React.Fragment    >
                    <Container key={"0"}     name ={"container"}  fluid ={false}   style={ qdmstyles.ywo } >
<Row key={"0"}     alignItems ={"stretch"}  direction ={"row"}  justify ={"flex-start"}  spacing ={"2"}   style={ qdmstyles.xZgpm } >
<QRscan key={"0"}       >
</QRscan>
</Row>
</Container>

                </React.Fragment>}
            </NamespacesConsumer>
        }
} 

const mapStateToProps = (state) => ({
    
});
  
export default connect(
    mapStateToProps,
    actions
)(withRouter(translate()(Qrcode)));