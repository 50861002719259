import React from 'react';
import { actions } from "real_wear_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer,translate } from 'react-i18next';
import qdmstyles  from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import './index.css';
import { languageOptions } from '../../utils'
import { LoggerContext } from '../../contexts'




import * as ALL from '../../components'

import { 
TextInput, 
Dropdown } from 'qdm-component-library';

class AddOnsiteDoctor extends React.Component{
        static contextType = LoggerContext
        constructor(props,context){
            super(props,context);
            this.state = {add_onsite_doctor_kuvin  : null,add_onsite_doctor_30pud  : null,add_onsite_doctor_p0dyf  : null,add_onsite_doctor_2dfng  : null,add_onsite_doctor_zelfs  : null,add_onsite_doctor_5jfdz  : null,add_onsite_doctor_ab7yv  : null}
        }

        changeState = (key, value) => {
            this.setState({
                [key]:value
            });
        }
        onchange_kuvin  = (e) => {
                    
                    this.setState({ add_onsite_doctor_kuvin : e?.target?.value })
                }
onchange_30pud  = (e) => {
                    
                    this.setState({ add_onsite_doctor_30pud : e })
                }
onchange_p0dyf  = (e) => {
                    
                    this.setState({ add_onsite_doctor_p0dyf : e })
                }
onchange_2dfng  = (e) => {
                    
                    this.setState({ add_onsite_doctor_2dfng : e?.target?.value })
                }
onchange_zelfs  = (e) => {
                    
                    this.setState({ add_onsite_doctor_zelfs : e?.target?.value })
                }
onchange_5jfdz  = (e) => {
                    
                    this.setState({ add_onsite_doctor_5jfdz : e?.target?.value })
                }
onclick_ab7yv  =  async (event,data) => {const payload0 = {

                                            add_onsite_doctor_kuvin  :  this.state.add_onsite_doctor_kuvin ,

                                            add_onsite_doctor_30pud  :  this.state.add_onsite_doctor_30pud ?.value,

                                            add_onsite_doctor_p0dyf  :  this.state.add_onsite_doctor_p0dyf ?.label,

                                            add_onsite_doctor_5jfdz  :  this.state.add_onsite_doctor_5jfdz ,

                                            add_onsite_doctor_zelfs  :  this.state.add_onsite_doctor_zelfs ,

                                            add_onsite_doctor_2dfng  :  this.state.add_onsite_doctor_2dfng ,history : this.props.history.location.state,data : data}
let data0 = await this.props.Add_Onsite_DoctorPractitioner_Onsight_Insert_Updated_Ab7Yv(payload0)
if(data0?.payload?.Code === 200){
                
                    window.location.href = "/onsite_doctor";
                
            }
                  
                
                }
        componentDidMount(){

             /// sample localstorage translation
             if(sessionStorage.defaultLng){
                const {i18n} = this.props;
                i18n.changeLanguage(sessionStorage.defaultLng)
            }
            
            
        }

        shouldComponentUpdate(props, state) {
            
        if(this.props?.Practitioner_Onsight_Insert_UpdatedData?.loading !== props?.Practitioner_Onsight_Insert_UpdatedData?.loading){
            if(!props?.Practitioner_Onsight_Insert_UpdatedData?.loading && props?.Practitioner_Onsight_Insert_UpdatedData.error){
                
            }else if(!props?.Practitioner_Onsight_Insert_UpdatedData?.loading && !props?.Practitioner_Onsight_Insert_UpdatedData.error){
                
        }
    }
        
            return true;
          }


        render(){

            
            const { PractitionerData,Practitioner_Onsight_Insert_UpdatedData ,data } = this.props;
            

            

            return <NamespacesConsumer>
                {(t, { i18n }) => <React.Fragment    >
                    <div key={"0"}       >
<input key={"0"}     type ={"hidden"}  id ={"anPageName"}    value ={"add-onsite-doctor"}    >
</input>
 <div key={"1"}     class ={"add-onsite-doctor screen"}    >
<div key={"0"}     class ={"group-93711-xaSxgS"}    >
<div key={"0"}     class ={"rectangle-47173-uFZgGH"}    >
</div>
 <div key={"1"}     class ={"group-93715-uFZgGH"}    >
<div key={"0"}     class ={"logo-EIHDxv"}    >
<img key={"0"}     class ={"x51-client_profile_subscription-10-AkHeNh"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183739820"}  alt ={"5-1-client-profile-subscription---10@1x.png"}    >
</img>
 <div key={"1"}     class ={"global-doctors-AkHeNh"}    >
<span key={"0"}     class ={"span0-W05HrE"}      >
Global</span>
 <span key={"1"}     class ={"span1-W05HrE"}      >
Doctors</span>
</div>
</div>
 <div key={"1"}     class ={"login-details-EIHDxv"}    >
<div key={"0"}     class ={"hello-7sMubk"}      >
Hello </div>
 <div key={"1"}     class ={"mr-rakin-7sMubk"}      >
Mr.Rakin</div>
 <div key={"2"}     class ={"sathishrakinainqacom-7sMubk"}      >
Sathish.rakin@ainqa.com</div>
 <img key={"3"}     class ={"line-9-7sMubk"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744502"}  alt ={"line-9@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"reports-EIHDxv"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/reports")
            }}
           class ={"reports-5fV0xx roboto-medium-dove-gray-14px"}      >
Reports</div>
 <img key={"1"}     class ={"download-7-5fV0xx"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744506"}  alt ={"download--7-@1x.png"}    >
</img>
</div>
 <div key={"3"}     class ={"patient-regn-EIHDxv"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/patient_registration")
            }}
           class ={"patient-registration-zxLbJl roboto-medium-dove-gray-14px"}      >
Patient Registration</div>
 <img key={"1"}     class ={"rectangle-47175-zxLbJl"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744525"}  alt ={"rectangle-47175@1x.png"}    >
</img>
</div>
 <div key={"4"}     class ={"rectangle-47179-EIHDxv"}    >
</div>
 <div key={"5"}     class ={"master-EIHDxv"}    >
<div key={"0"}     class ={"master-MQE0f4"}      >
Master</div>
 <img key={"1"}     class ={"material-master-data-MQE0f4"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744545"}  alt ={"material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"}    >
</img>
</div>
 <div key={"6"}     class ={"group-93714-EIHDxv"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/visit_registration")
            }}
           class ={"visit-registration-t3t4kL roboto-medium-dove-gray-14px"}      >
Visit Registration</div>
 <img key={"1"}     class ={"download-6-t3t4kL"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744562"}  alt ={"download--6-@1x.png"}    >
</img>
</div>
</div>
</div>
 <div key={"1"}     class ={"version-xaSxgS"}    >
<div key={"0"}     class ={"v1001-5QYxRx"}      >
v1.0.01</div>
</div>
 <div key={"2"}  
            onClick={() => { 
                
                this.props.history.push("/specialist_doctor")
            }}
           class ={"specialist-doctor-xaSxgS"}      >
Specialist Doctor</div>
 <div key={"3"}  
            onClick={() => { 
                
                this.props.history.push("/patient_facility")
            }}
           class ={"patient-facility-xaSxgS"}      >
Patient Facility</div>
 <div key={"4"}     class ={"onsite-doctor-xaSxgS"}    >
<div key={"0"}     class ={"onsite-doctor-medical-assistant-LYBOw5"}      >
Onsite Doctor/Medical Assistant</div>
 <img key={"1"}     class ={"rectangle-47181-LYBOw5"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183770632"}  alt ={"rectangle-47181@1x.png"}    >
</img>
</div>
 <div key={"5"}     class ={"group-93712-xaSxgS"}    >
<div key={"0"}     class ={"rectangle-47180-Td8rXh"}    >
</div>
 <div key={"1"}     class ={"login-avatar-Td8rXh"}    >
<div key={"0"}     class ={"rectangle-47177-gh1v2R"}    >
</div>
 <img key={"1"}     class ={"rectangle-47178-gh1v2R"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744508"}  alt ={"rectangle-47178@1x.png"}    >
</img>
 <div key={"2"}     class ={"rakin-gh1v2R"}      >
RAKIN</div>
 <img key={"3"}     class ={"icon-awesome-chevron-down-gh1v2R"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744504"}  alt ={"icon-awesome-chevron-down@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"master-Td8rXh"}      >
Master</div>
</div>
 <div key={"6"}     class ={"group-93704-xaSxgS"}    >
<div key={"0"}     class ={"rectangle-47165-5k5OiQ"}    >
</div>
 <div key={"1"}     class ={"add-onsite-doctor-medical-assistant-5k5OiQ"}      >
Add Onsite Doctor/Medical Assistant</div>
 <img key={"2"}     class ={"line-3-5k5OiQ"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183770463"}  alt ={"line-3@1x.png"}    >
</img>
 <div key={"3"}     class ={"component-28-5k5OiQ"}    >
<div key={"0"}     class ={"qdm-textinput-f7PnHK"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_kuvin (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Doctor Name")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
</div>
 <div key={"4"}     class ={"component-70-5k5OiQ"}    >
<div key={"0"}     class ={"qdm-textinput-tRM7QO"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_2dfng (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Email Id")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
</div>
 <div key={"5"}     class ={"component-71-5k5OiQ"}    >
<div key={"0"}     class ={"qdm-textinput-WuHEB3"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_5jfdz (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Address")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
</div>
 <div key={"6"}     class ={"component-93700-5k5OiQ"}    >
<div key={"0"}     class ={"qdm-dropdown-OzmzFQ"}    >
<Dropdown key={"0"} onChange = {(e) => this.onchange_p0dyf (e) }    elevation ={"1"}  fullWidth ={true}  dropDownTextSize ={"14"}  dropDownTextColor ={"#000"}  activeTextColor ={"#000"}  dropdownListMargin ={"0"}  dropdownListPadding ={"8"}  options ={[{"value":"Chennai","label":"Chennai"},{"value":"Coimbatore","label":"Coimbatore"},{"value":"Madurai","label":"Madurai"},{"value":"Trichy","label":"Trichy"},{"value":"Kochi","label":"Kochi"},{"value":"Kuala Lumpur","label":"Kuala Lumpur"}]}  label= {t("Location")}  isRequired ={true}  Url ={""}  keyName ={""}  valueName ={""}   style={ qdmstyles.PdyF } >
</Dropdown>
</div>
</div>
 <div key={"7"}     class ={"component-93700-4UlSRf"}    >
<div key={"0"}     class ={"qdm-dropdown-Mdxby5"}    >
<Dropdown key={"0"} onChange = {(e) => this.onchange_30pud (e) }    elevation ={"1"}  fullWidth ={true}  dropDownTextSize ={"14"}  dropDownTextColor ={"#000"}  activeTextColor ={"#000"}  dropdownListMargin ={"0"}  dropdownListPadding ={"8"}  options ={[{"value":"Surgery","label":"Surgery"},{"value":"Pediatrics","label":"Pediatrics"},{"value":"Neurology","label":"Neurology"},{"value":"Radiology","label":"Radiology"}]}  label= {t("Speciality")}  isRequired ={true}  Url ={""}  keyName ={""}  valueName ={""}   style={ qdmstyles.PUD } >
</Dropdown>
</div>
</div>
 <div key={"8"}     class ={"component-93700-dF6Wax"}    >
<div key={"0"}     class ={"qdm-textinput-Op6AGD"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_zelfs (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Mobile Number")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
</div>
 <div key={"9"}     class ={"group-93708-5k5OiQ"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/onsite_doctor")
            }}
           class ={"back-Mo1rQz"}      >
Back</div>
 <img key={"1"}     class ={"path-734-Mo1rQz"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183772612"}  alt ={"path-734@1x.png"}    >
</img>
</div>
 <div key={"10"}     class ={"qdm-button-5k5OiQ"}    >
<div key={"0"} onClick = {(e) => this.onclick_ab7yv (e) }    class ={"submit-rY6VxM"}      >
Submit</div>
</div>
</div>
</div>
</div>

                </React.Fragment>}
            </NamespacesConsumer>
        }
} 

const mapStateToProps = (state) => ({
    Practitioner_Onsight_Insert_UpdatedData:state?.Add_Onsite_DoctorPractitioner_Onsight_Insert_Updated_Ab7YvReducer?.Practitioner_Onsight_Insert_Updated_Ab7Yv?.data,
});
  
export default connect(
    mapStateToProps,
    actions
)(withRouter(translate()(AddOnsiteDoctor)));