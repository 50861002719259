import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Routes } from "./routes";

import {
  NotFound,
  Login,
PatientRegistration,
VisitRegistration,
SpecialistDoctor,
OnsiteDoctor,
PatientFacility,
AddSpecialistDoctor,
EditSpecialistDoctor,
AddOnsiteDoctor,
EditOnsiteDoctor,
AddPatientFacility,
Reports,

} from './../screens';

const RouterApp = (props) => {

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />

        
            
				{/*Login Route */} 
 <Route path={Routes.login} component={Login} />
        
            
				{/*Patient_Registration Route */} 
 <Route path={Routes.patientRegistration} component={PatientRegistration} />
        
            
				{/*Visit_Registration Route */} 
 <Route path={Routes.visitRegistration} component={VisitRegistration} />
        
            
				{/*Specialist_Doctor Route */} 
 <Route path={Routes.specialistDoctor} component={SpecialistDoctor} />
        
            
				{/*Onsite_Doctor Route */} 
 <Route path={Routes.onsiteDoctor} component={OnsiteDoctor} />
        
            
				{/*Patient_Facility Route */} 
 <Route path={Routes.patientFacility} component={PatientFacility} />
        
            
				{/*Add_Specialist_Doctor Route */} 
 <Route path={Routes.addSpecialistDoctor} component={AddSpecialistDoctor} />
        
            
				{/*Edit_Specialist_Doctor Route */} 
 <Route path={Routes.editSpecialistDoctor} component={EditSpecialistDoctor} />
        
            
				{/*Add_Onsite_Doctor Route */} 
 <Route path={Routes.addOnsiteDoctor} component={AddOnsiteDoctor} />
        
            
				{/*Edit_Onsite_Doctor Route */} 
 <Route path={Routes.editOnsiteDoctor} component={EditOnsiteDoctor} />
        
            
				{/*Add_Patient_Facility Route */} 
 <Route path={Routes.addPatientFacility} component={AddPatientFacility} />
        
            
				{/*Reports Route */} 
 <Route path={Routes.reports} component={Reports} />
        

        {/* For unknow/non-defined path */}
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;