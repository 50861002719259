import React from 'react';
import { actions } from "real_wear_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer,translate } from 'react-i18next';
import qdmstyles  from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import './index.css';
import { languageOptions } from '../../utils'
import { LoggerContext } from '../../contexts'




import * as ALL from '../../components'



class Success extends React.Component{
        static contextType = LoggerContext
        constructor(props,context){
            super(props,context);
            this.state = {}
        }

        changeState = (key, value) => {
            this.setState({
                [key]:value
            });
        }
        
        componentDidMount(){

             /// sample localstorage translation
             if(sessionStorage.defaultLng){
                const {i18n} = this.props;
                i18n.changeLanguage(sessionStorage.defaultLng)
            }
            
            
        }

        shouldComponentUpdate(props, state) {
            
            return true;
          }


        render(){

            

            

            return <NamespacesConsumer>
                {(t, { i18n }) => <React.Fragment    >
                    <div key={"0"}       >
<input key={"0"}     type ={"hidden"}  id ={"anPageName"}    value ={"success-dialog"}    >
</input>
 <div key={"1"}     class ={"container-center-horizontal"}    >
<div key={"0"}     class ={"success-dialog screen"}    >
<div key={"0"}     class ={"group-93701-MC2f8T"}    >
<div key={"0"}     class ={"group-11-7B1UcH"}    >
<div key={"0"}     class ={"rectangle-20-34L3Wo"}    >
</div>
</div>
 <img key={"1"}     class ={"greentick-7B1UcH"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/182294822"}  alt ={"greentick@1x.png"}    >
</img>
 <div key={"2"}     class ={"registration-success-7B1UcH"}      >
REGISTRATION SUCCESS</div>
 <div key={"3"}     class ={"group-13-7B1UcH"}    >
<div key={"0"}     class ={"rectangle-26-2s0qiD"}    >
</div>
 <p key={"1"}     class ={"thank-you-for-the-re-2s0qiD"}      >
Thank you for the registration. Your Medical Registration Number</p>
 <div key={"2"}     class ={"mrn-mjgu7643-k-2s0qiD"}      >
(MRN) : MJGU7643K</div>
</div>
 <div key={"4"}     class ={"close-7B1UcH"}    >
<div key={"0"}     class ={"close-H39msy"}      >
Close</div>
</div>
 <div key={"5"}     class ={"group-14-7B1UcH"}    >
<div key={"0"}     class ={"patient-name-sly5Zj roboto-normal-concord-14px"}      >
Patient name</div>
 <div key={"1"}     class ={"administrator-sly5Zj roboto-medium-black-14px"}      >
Administrator</div>
 <div key={"2"}     class ={"x1234567890-sly5Zj roboto-medium-black-14px"}      >
1234567890</div>
 <div key={"3"}     class ={"dfhs5-d7-sly5Zj roboto-medium-black-14px"}      >
DFHS5D7</div>
 <div key={"4"}     class ={"x654343-sly5Zj roboto-medium-black-14px"}      >
654343</div>
 <div key={"5"}     class ={"malaysia-sly5Zj roboto-medium-black-14px"}      >
MALAYSIA</div>
 <div key={"6"}     class ={"mobile-no-sly5Zj roboto-normal-concord-14px"}      >
Mobile No</div>
 <div key={"7"}     class ={"id-card-sly5Zj roboto-normal-concord-14px"}      >
ID Card</div>
 <div key={"8"}     class ={"emp-no-sly5Zj roboto-normal-concord-14px"}      >
EMP No</div>
 <div key={"9"}     class ={"facility-sly5Zj roboto-normal-concord-14px"}      >
Facility</div>
 <div key={"10"}     class ={"x-sly5Zj roboto-medium-sonic-silver-14px"}      >
:</div>
 <div key={"11"}     class ={"x-M4G9ma roboto-medium-sonic-silver-14px"}      >
:</div>
 <div key={"12"}     class ={"x-w5nauq roboto-medium-sonic-silver-14px"}      >
:</div>
 <div key={"13"}     class ={"x-qq4qva roboto-medium-sonic-silver-14px"}      >
:</div>
 <div key={"14"}     class ={"x-vojvqU roboto-medium-sonic-silver-14px"}      >
:</div>
</div>
</div>
</div>
</div>
</div>

                </React.Fragment>}
            </NamespacesConsumer>
        }
} 

const mapStateToProps = (state) => ({
    
});
  
export default connect(
    mapStateToProps,
    actions
)(withRouter(translate()(Success)));