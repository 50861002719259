import React from 'react';
import { actions } from "real_wear_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer,translate } from 'react-i18next';
import qdmstyles  from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import './index.css';
import { languageOptions } from '../../utils'
import { LoggerContext } from '../../contexts'




import * as ALL from '../../components'

import { 
div } from 'qdm-component-library';

class PatientFacility extends React.Component{
        static contextType = LoggerContext
        constructor(props,context){
            super(props,context);
            this.state = {patient_facility_ldasc  : null}
        }

        changeState = (key, value) => {
            this.setState({
                [key]:value
            });
        }
        
        componentDidMount(){

             /// sample localstorage translation
             if(sessionStorage.defaultLng){
                const {i18n} = this.props;
                i18n.changeLanguage(sessionStorage.defaultLng)
            }
            
            
                        
                        const payload = { history : this.props.history.location.state }
                        this.props.Patient_FacilityFacility_Full_Read_LdASC(payload)
                    
        }

        shouldComponentUpdate(props, state) {
            
        if(this.props?.Facility_Full_ReadData?.loading !== props?.Facility_Full_ReadData?.loading){
            if(!props?.Facility_Full_ReadData?.loading && props?.Facility_Full_ReadData.error){
                
            }else if(!props?.Facility_Full_ReadData?.loading && !props?.Facility_Full_ReadData.error){
                
        }
    }
        
            return true;
          }


        render(){

            
            const { FacilityData,Facility_Full_ReadData ,data } = this.props;
            

            

            return <NamespacesConsumer>
                {(t, { i18n }) => <React.Fragment    >
                    <div key={"0"}       >
<input key={"0"}     type ={"hidden"}  id ={"anPageName"}    value ={"master-patient-facility"}    >
</input>
 <div key={"1"}     class ={"master-patient-facility screen"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/specialist_doctor")
            }}
           class ={"specialist-doctor-5vmi5X"}      >
Specialist Doctor</div>
 <div key={"1"}     class ={"qdm-button-5vmi5X"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/add_patient_facility")
            }}
           class ={"add-new-rUDBvK"}      >
Add New</div>
</div>
 <div key={"2"}  
            onClick={() => { 
                
                this.props.history.push("/onsite_doctor")
            }}
           class ={"onsite-doctor-medical-assistant-5vmi5X"}      >
Onsite Doctor/Medical Assistant</div>
 <div key={"3"}     class ={"group-93717-5vmi5X"}    >
<div key={"0"}     class ={"rectangle-47148-hQXPyx"}    >
</div>
 <div key={"1"}     class ={"group-93702-hQXPyx"}    >
<div key={"0"}     class ={"facility-name-zQ1ECm roboto-medium-woodsmoke-14px"}      >
Facility Name</div>
 <div key={"1"}     class ={"address-zQ1ECm roboto-medium-woodsmoke-14px"}      >
Address</div>
 <div key={"2"}     class ={"state-zQ1ECm roboto-medium-woodsmoke-14px"}      >
State</div>
 <div key={"3"}     class ={"city-zQ1ECm roboto-medium-woodsmoke-14px"}      >
City</div>
 <div key={"4"}     class ={"postal-code-zQ1ECm roboto-medium-woodsmoke-14px"}      >
Postal code</div>
</div>
 <div key={"2"}     class ={"qdm-list-hQXPyx"}     style={ qdmstyles.HoHM } >
{Array.isArray(Facility_Full_ReadData?.['result']) && Facility_Full_ReadData?.['result']?.map((data , index)=>{
                      return <React.Fragment key={index}><div key={"0"}     class ={"listitem-Mi250Z"}    >
<div key={"0"}     class ={"tanyacare-9x4qpe roboto-normal-boulder-11px"}      >
{data?.Facility_Name}</div>
 <div key={"1"}     class ={"chennai-9x4qpe roboto-normal-boulder-11px"}      >
{data?.Address}</div>
 <img key={"2"}     class ={"line-4-9x4qpe"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801417"}  alt ={"line-4@1x.png"}    >
</img>
 <div key={"3"}     class ={"tamilnadu-9x4qpe roboto-normal-boulder-11px"}      >
{data?.State}</div>
 <div key={"4"}     class ={"chennai-wswQxM roboto-normal-boulder-11px"}      >
{data?.City}</div>
 <div key={"5"}     class ={"x600024-9x4qpe roboto-normal-boulder-11px"}      >
{data?.PostalCode}</div>
</div>
</React.Fragment>
                  })}{data?.result}</div>
 <img key={"3"}     class ={"line-2-hQXPyx"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801410"}  alt ={"line-2@1x.png"}    >
</img>
</div>
 <div key={"4"}     class ={"patient-facility-5vmi5X"}    >
<div key={"0"}     class ={"patient-facility-UADC48"}      >
Patient Facility</div>
 <img key={"1"}     class ={"rectangle-47182-UADC48"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801445"}  alt ={"rectangle-47182@1x.png"}    >
</img>
</div>
 <div key={"5"}     class ={"group-93711-5vmi5X"}    >
<div key={"0"}     class ={"rectangle-47173-8Gxuvg"}    >
</div>
 <div key={"1"}     class ={"group-93715-8Gxuvg"}    >
<div key={"0"}     class ={"logo-QJDPFy"}    >
<img key={"0"}     class ={"x51-client_profile_subscription-10-JefTXo"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801383"}  alt ={"5-1-client-profile-subscription---10@1x.png"}    >
</img>
 <div key={"1"}     class ={"global-doctors-JefTXo"}    >
<span key={"0"}     class ={"span0-MMDxyo"}      >
Global</span>
 <span key={"1"}     class ={"span1-MMDxyo"}      >
Doctors</span>
</div>
</div>
 <div key={"1"}     class ={"login-details-QJDPFy"}    >
<div key={"0"}     class ={"hello-7Q2FDr"}      >
Hello </div>
 <div key={"1"}     class ={"mr-rakin-7Q2FDr"}      >
Mr.Rakin</div>
 <div key={"2"}     class ={"sathishrakinainqacom-7Q2FDr"}      >
Sathish.rakin@ainqa.com</div>
 <img key={"3"}     class ={"line-9-7Q2FDr"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801422"}  alt ={"line-9@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"reports-QJDPFy"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/reports")
            }}
           class ={"reports-p2FbPI roboto-medium-dove-gray-14px"}      >
Reports</div>
 <img key={"1"}     class ={"download-7-p2FbPI"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801397"}  alt ={"download--7-@1x.png"}    >
</img>
</div>
 <div key={"3"}     class ={"patient-regn-QJDPFy"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/patient_registration")
            }}
           class ={"patient-registration-kvA40r roboto-medium-dove-gray-14px"}      >
Patient Registration</div>
 <img key={"1"}     class ={"rectangle-47175-kvA40r"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801452"}  alt ={"rectangle-47175@1x.png"}    >
</img>
</div>
 <div key={"4"}     class ={"rectangle-47179-QJDPFy"}    >
</div>
 <div key={"5"}     class ={"master-QJDPFy"}    >
<div key={"0"}     class ={"master-5173Yj"}      >
Master</div>
 <img key={"1"}     class ={"material-master-data-5173Yj"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801432"}  alt ={"material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"}    >
</img>
</div>
 <div key={"6"}     class ={"group-93714-QJDPFy"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/visit_registration")
            }}
           class ={"visit-registration-pqxGPt roboto-medium-dove-gray-14px"}      >
Visit Registration</div>
 <img key={"1"}     class ={"download-6-pqxGPt"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801390"}  alt ={"download--6-@1x.png"}    >
</img>
</div>
</div>
</div>
 <div key={"6"}     class ={"version-5vmi5X"}    >
<div key={"0"}     class ={"v1001-UmVqgD"}      >
v1.0.01</div>
</div>
 <div key={"7"}     class ={"group-93712-5vmi5X"}    >
<div key={"0"}     class ={"rectangle-47180-V34HkV"}    >
</div>
 <div key={"1"}     class ={"login-avatar-V34HkV"}    >
<div key={"0"}     class ={"rectangle-47177-7gfhGx"}    >
</div>
 <img key={"1"}     class ={"rectangle-47178-7gfhGx"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801443"}  alt ={"rectangle-47178@1x.png"}    >
</img>
 <div key={"2"}     class ={"rakin-7gfhGx"}      >
RAKIN</div>
 <img key={"3"}     class ={"icon-awesome-chevron-down-7gfhGx"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801405"}  alt ={"icon-awesome-chevron-down@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"master-V34HkV"}      >
Master</div>
</div>
</div>
</div>

                </React.Fragment>}
            </NamespacesConsumer>
        }
} 

const mapStateToProps = (state) => ({
    Facility_Full_ReadData:state?.Patient_FacilityFacility_Full_Read_LdASCReducer?.Facility_Full_Read_LdASC?.data,
});
  
export default connect(
    mapStateToProps,
    actions
)(withRouter(translate()(PatientFacility)));