import React from 'react';
import { actions } from "real_wear_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer,translate } from 'react-i18next';
import qdmstyles  from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import './index.css';
import { languageOptions } from '../../utils'
import { LoggerContext } from '../../contexts'




import * as ALL from '../../components'

import { 
TextInput, 
Dropdown } from 'qdm-component-library';

class AddPatientFacility extends React.Component{
        static contextType = LoggerContext
        constructor(props,context){
            super(props,context);
            this.state = {add_patient_facility_wiaaz  : null,add_patient_facility_cmakn  : null,add_patient_facility_7d15q  : null,add_patient_facility_yge4x  : null,add_patient_facility_wt2yw  : null,add_patient_facility_ifvkt  : null}
        }

        changeState = (key, value) => {
            this.setState({
                [key]:value
            });
        }
        onclick_wiaaz  =  async (event,data) => {const payload0 = {

                                            add_patient_facility_cmakn  :  this.state.add_patient_facility_cmakn ?.label,

                                            add_patient_facility_wt2yw  :  this.state.add_patient_facility_wt2yw ,

                                            add_patient_facility_7d15q  :  this.state.add_patient_facility_7d15q ?.label,

                                            add_patient_facility_yge4x  :  this.state.add_patient_facility_yge4x ?.label,

                                            add_patient_facility_ifvkt  :  this.state.add_patient_facility_ifvkt ,history : this.props.history.location.state,data : data}
let data0 = await this.props.Add_Patient_FacilityFacility_Insert_wIAaZ(payload0)
if(data0?.payload?.Code === 200){
                
                    window.location.href = "/patient_facility";
                
            }
                  
                
                }
onchange_cmakn  = (e) => {
                    
                    this.setState({ add_patient_facility_cmakn : e })
                }
onchange_7d15q  = (e) => {
                    
                    this.setState({ add_patient_facility_7d15q : e })
                }
onchange_yge4x  = (e) => {
                    
                    this.setState({ add_patient_facility_yge4x : e })
                }
onchange_wt2yw  = (e) => {
                    
                    this.setState({ add_patient_facility_wt2yw : e?.target?.value })
                }
onchange_ifvkt  = (e) => {
                    
                    this.setState({ add_patient_facility_ifvkt : e?.target?.value })
                }
        componentDidMount(){

             /// sample localstorage translation
             if(sessionStorage.defaultLng){
                const {i18n} = this.props;
                i18n.changeLanguage(sessionStorage.defaultLng)
            }
            
            
        }

        shouldComponentUpdate(props, state) {
            
            return true;
          }


        render(){

            

            

            return <NamespacesConsumer>
                {(t, { i18n }) => <React.Fragment    >
                    <div key={"0"}       >
<input key={"0"}     type ={"hidden"}  id ={"anPageName"}    value ={"add-patient-facility"}    >
</input>
 <div key={"1"}     class ={"add-patient-facility screen"}    >
<div key={"0"}     class ={"group-93706-lMAO0f"}    >
<div key={"0"}     class ={"rectangle-47165-qkxrM8"}    >
</div>
 <div key={"1"}     class ={"add-patient-facility-qkxrM8"}      >
Add Patient Facility</div>
 <img key={"2"}     class ={"line-3-qkxrM8"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183770463"}  alt ={"line-3@1x.png"}    >
</img>
 <div key={"3"}     class ={"qdm-textinput-qkxrM8"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_wt2yw (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Address")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
 <div key={"4"}     class ={"qdm-textinput-TtxiSe"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_ifvkt (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Postal Code")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={false}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
 <div key={"5"}     class ={"qdm-dropdown-qkxrM8"}    >
<Dropdown key={"0"} onChange = {(e) => this.onchange_yge4x (e) }    elevation ={"1"}  fullWidth ={true}  dropDownTextSize ={"14"}  dropDownTextColor ={"#000"}  activeTextColor ={"#000"}  dropdownListMargin ={"0"}  dropdownListPadding ={"8"}  options ={[{"value":"Chennai","label":"Chennai"},{"value":"Kochi","label":"Kochi"},{"value":"Salem","label":"Salem"},{"value":"Kuala Lumpur","label":"Kuala Lumpur"},{"value":"Johor Bahru","label":"Johor Bahru"}]}  label= {t("City")}  isRequired ={true}  Url ={""}  keyName ={""}  valueName ={""}   style={ qdmstyles.YGEX } >
</Dropdown>
</div>
 <div key={"6"}     class ={"qdm-dropdown-TtxiSe"}    >
<Dropdown key={"0"} onChange = {(e) => this.onchange_7d15q (e) }    elevation ={"1"}  fullWidth ={true}  dropDownTextSize ={"14"}  dropDownTextColor ={"#000"}  activeTextColor ={"#000"}  dropdownListMargin ={"0"}  dropdownListPadding ={"8"}  options ={[{"value":"Tamil Nadu","label":"Tamil Nadu"},{"value":"Sabah","label":"Sabah"},{"value":"Johor","label":"Johor"},{"value":"Kerala","label":"Kerala"}]}  label= {t("State")}  isRequired ={true}  Url ={""}  keyName ={""}  valueName ={""}   style={ qdmstyles.dQ } >
</Dropdown>
</div>
 <div key={"7"}     class ={"group-93708-qkxrM8"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/patient_facility")
            }}
           class ={"back-jdWd57"}      >
Back</div>
 <img key={"1"}     class ={"path-734-jdWd57"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183772612"}  alt ={"path-734@1x.png"}    >
</img>
</div>
 <div key={"8"}     class ={"qdm-button-qkxrM8"}    >
<div key={"0"} onClick = {(e) => this.onclick_wiaaz (e) }    class ={"submit-eNmFnb"}      >
Submit</div>
</div>
 <div key={"9"}     class ={"qdm-dropdown-mwqDjR"}    >
<Dropdown key={"0"} onChange = {(e) => this.onchange_cmakn (e) }    elevation ={"1"}  fullWidth ={true}  dropDownTextSize ={"14"}  dropDownTextColor ={"#000"}  activeTextColor ={"#000"}  dropdownListMargin ={"0"}  dropdownListPadding ={"8"}  options ={[{"value":"Chennai Health Center","label":"Chennai Health Center"},{"value":"Kerala Health Center","label":"Kerala Health Center"},{"value":"Sabah Health Center","label":"Sabah Health Center"}]}  label= {t("Facility Name")}  isRequired ={true}  Url ={""}  keyName ={""}  valueName ={""}  placeholder ={"Search Location"}  name= {t("Patient Facility")}   style={ qdmstyles.cmAkN } >
</Dropdown>
</div>
</div>
 <div key={"1"}     class ={"group-93711-lMAO0f"}    >
<div key={"0"}     class ={"rectangle-47173-kKjjEM"}    >
</div>
 <div key={"1"}     class ={"group-93715-kKjjEM"}    >
<div key={"0"}     class ={"logo-St6QWm"}    >
<img key={"0"}     class ={"x51-client_profile_subscription-10-4I3GO3"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183739820"}  alt ={"5-1-client-profile-subscription---10@1x.png"}    >
</img>
 <div key={"1"}     class ={"global-doctors-4I3GO3"}    >
<span key={"0"}     class ={"span0-eCR2Na"}      >
Global</span>
 <span key={"1"}     class ={"span1-eCR2Na"}      >
Doctors</span>
</div>
</div>
 <div key={"1"}     class ={"login-details-St6QWm"}    >
<div key={"0"}     class ={"hello-WisTeV"}      >
Hello </div>
 <div key={"1"}     class ={"mr-rakin-WisTeV"}      >
Mr.Rakin</div>
 <div key={"2"}     class ={"sathishrakinainqacom-WisTeV"}      >
Sathish.rakin@ainqa.com</div>
 <img key={"3"}     class ={"line-9-WisTeV"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744502"}  alt ={"line-9@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"reports-St6QWm"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/reports")
            }}
           class ={"reports-JpmTmj roboto-medium-dove-gray-14px"}      >
Reports</div>
 <img key={"1"}     class ={"download-7-JpmTmj"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744506"}  alt ={"download--7-@1x.png"}    >
</img>
</div>
 <div key={"3"}     class ={"patient-regn-St6QWm"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/patient_registration")
            }}
           class ={"patient-registration-EdZGZ0 roboto-medium-dove-gray-14px"}      >
Patient Registration</div>
 <img key={"1"}     class ={"rectangle-47175-EdZGZ0"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744525"}  alt ={"rectangle-47175@1x.png"}    >
</img>
</div>
 <div key={"4"}     class ={"rectangle-47179-St6QWm"}    >
</div>
 <div key={"5"}     class ={"master-St6QWm"}    >
<div key={"0"}     class ={"master-ERWKaM"}      >
Master</div>
 <img key={"1"}     class ={"material-master-data-ERWKaM"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744545"}  alt ={"material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"}    >
</img>
</div>
 <div key={"6"}     class ={"group-93714-St6QWm"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/visit_registration")
            }}
           class ={"visit-registration-TD0Ne6 roboto-medium-dove-gray-14px"}      >
Visit Registration</div>
 <img key={"1"}     class ={"download-6-TD0Ne6"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744562"}  alt ={"download--6-@1x.png"}    >
</img>
</div>
</div>
</div>
 <div key={"2"}     class ={"version-lMAO0f"}    >
<div key={"0"}     class ={"v1001-xRorvX"}      >
v1.0.01</div>
</div>
 <div key={"3"}  
            onClick={() => { 
                
                this.props.history.push("/specialist_doctor")
            }}
           class ={"specialist-doctor-lMAO0f"}      >
Specialist Doctor</div>
 <div key={"4"}  
            onClick={() => { 
                
                this.props.history.push("/onsite_doctor")
            }}
           class ={"onsite-doctor-medical-assistant-lMAO0f"}      >
Onsite Doctor/Medical Assistant</div>
 <div key={"5"}     class ={"patient-facility-lMAO0f"}    >
<div key={"0"}     class ={"patient-facility-yxGVMi"}      >
Patient Facility</div>
 <img key={"1"}     class ={"rectangle-47182-yxGVMi"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183771483"}  alt ={"rectangle-47182@1x.png"}    >
</img>
</div>
 <div key={"6"}     class ={"group-93712-lMAO0f"}    >
<div key={"0"}     class ={"rectangle-47180-Run8od"}    >
</div>
 <div key={"1"}     class ={"login-avatar-Run8od"}    >
<div key={"0"}     class ={"rectangle-47177-Vy2c0I"}    >
</div>
 <img key={"1"}     class ={"rectangle-47178-Vy2c0I"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744508"}  alt ={"rectangle-47178@1x.png"}    >
</img>
 <div key={"2"}     class ={"rakin-Vy2c0I"}      >
RAKIN</div>
 <img key={"3"}     class ={"icon-awesome-chevron-down-Vy2c0I"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744504"}  alt ={"icon-awesome-chevron-down@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"master-Run8od"}      >
Master</div>
</div>
</div>
</div>

                </React.Fragment>}
            </NamespacesConsumer>
        }
} 

const mapStateToProps = (state) => ({
    
});
  
export default connect(
    mapStateToProps,
    actions
)(withRouter(translate()(AddPatientFacility)));