import React from 'react';
import { actions } from "real_wear_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer,translate } from 'react-i18next';
import qdmstyles  from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import './index.css';
import { languageOptions } from '../../utils'
import { LoggerContext } from '../../contexts'




import * as ALL from '../../components'

import { 
div } from 'qdm-component-library';

class Reports extends React.Component{
        static contextType = LoggerContext
        constructor(props,context){
            super(props,context);
            this.state = {reports_2opew  : null}
        }

        changeState = (key, value) => {
            this.setState({
                [key]:value
            });
        }
        
        componentDidMount(){

             /// sample localstorage translation
             if(sessionStorage.defaultLng){
                const {i18n} = this.props;
                i18n.changeLanguage(sessionStorage.defaultLng)
            }
            
            
                        
                        const payload = { history : this.props.history.location.state }
                        this.props.ReportsEncounter_Full_Read_2opEW(payload)
                    
        }

        shouldComponentUpdate(props, state) {
            
        if(this.props?.Encounter_Full_ReadData?.loading !== props?.Encounter_Full_ReadData?.loading){
            if(!props?.Encounter_Full_ReadData?.loading && props?.Encounter_Full_ReadData.error){
                
            }else if(!props?.Encounter_Full_ReadData?.loading && !props?.Encounter_Full_ReadData.error){
                
        }
    }
        
            return true;
          }


        render(){

            
            const { EncounterData,Encounter_Full_ReadData ,data } = this.props;
            

            

            return <NamespacesConsumer>
                {(t, { i18n }) => <React.Fragment    >
                    <div key={"0"}       >
<input key={"0"}     type ={"hidden"}  id ={"anPageName"}    value ={"reports"}    >
</input>
</div>
 <div key={"1"}       >
<input key={"0"}     type ={"hidden"}  id ={"anPageName"}    value ={"reports"}    >
</input>
 <div key={"1"}     class ={"reports screen"}    >
<div key={"0"}     class ={"group-93718-3zrh10"}    >
<div key={"0"}     class ={"rectangle-47148-zcRaaK"}    >
</div>
 <div key={"1"}     class ={"group-93702-zcRaaK"}    >
<div key={"0"}     class ={"name-cVadP6 roboto-medium-woodsmoke-14px"}      >
Name</div>
 <div key={"1"}     class ={"mrn-cVadP6 roboto-medium-woodsmoke-14px"}      >
MRN</div>
 <div key={"2"}     class ={"encounter-no-cVadP6 roboto-medium-woodsmoke-14px"}      >
Encounter No</div>
 <div key={"3"}     class ={"date-time-cVadP6 roboto-medium-woodsmoke-14px"}      >
Date &amp; Time</div>
 <div key={"4"}     class ={"duration-of-session-cVadP6 roboto-medium-woodsmoke-14px"}      >
Duration of Session</div>
 <div key={"5"}     class ={"consultant-cVadP6 roboto-medium-woodsmoke-14px"}      >
Consultant</div>
</div>
 <img key={"2"}     class ={"line-2-zcRaaK"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801410"}  alt ={"line-2@1x.png"}    >
</img>
 <div key={"3"}     class ={"qdm-list-zcRaaK"}     style={ qdmstyles.XLPyZ } >
{Array.isArray(Encounter_Full_ReadData?.['result']) && Encounter_Full_ReadData?.['result']?.map((data , index)=>{
                      return <React.Fragment key={index}><div key={"0"}     class ={"listitem-9n1jZJ"}    >
<div key={"0"}     class ={"administrator-eXCdcl roboto-normal-dove-gray-11px"}      >
{data?.Meeting_ID}</div>
 <div key={"1"}     class ={"x56789568-eXCdcl roboto-normal-dove-gray-11px"}      >
56789568</div>
 <div key={"2"}     class ={"x3-eXCdcl roboto-normal-dove-gray-11px"}      >
{data?.Encounter_Number}</div>
 <div key={"3"}     class ={"x12122021-0900-pm-eXCdcl roboto-normal-dove-gray-11px"}      >
{t("{data?.createddate}")}</div>
 <div key={"4"}     class ={"x3000-mins-eXCdcl roboto-normal-dove-gray-11px"}      >
30:00 mins</div>
 <div key={"5"}     class ={"specialist-eXCdcl roboto-normal-dove-gray-11px"}      >
Specialist</div>
 <img key={"6"}     class ={"line-3-eXCdcl"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801920"}  alt ={"line-3@1x.png"}    >
</img>
</div>
</React.Fragment>
                  })}{data?.result}</div>
</div>
 <div key={"1"}     class ={"group-93712-3zrh10"}    >
<div key={"0"}     class ={"rectangle-47180-IEZVGu"}    >
</div>
 <div key={"1"}     class ={"login-avatar-IEZVGu"}    >
<div key={"0"}     class ={"rectangle-47177-BShlaI"}    >
</div>
 <img key={"1"}     class ={"rectangle-47178-BShlaI"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801443"}  alt ={"rectangle-47178@1x.png"}    >
</img>
 <div key={"2"}     class ={"rakin-BShlaI"}      >
RAKIN</div>
 <img key={"3"}     class ={"icon-awesome-chevron-down-BShlaI"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801405"}  alt ={"icon-awesome-chevron-down@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"reports-IEZVGu"}      >
Reports</div>
</div>
 <div key={"2"}     class ={"group-93711-3zrh10"}    >
<div key={"0"}     class ={"rectangle-47173-glUWHL"}    >
</div>
 <div key={"1"}     class ={"group-93715-glUWHL"}    >
<div key={"0"}     class ={"logo-38rwxk"}    >
<img key={"0"}     class ={"x51-client_profile_subscription-10-Nixsk1"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801383"}  alt ={"5-1-client-profile-subscription---10@1x.png"}    >
</img>
 <div key={"1"}     class ={"global-doctors-Nixsk1"}    >
<span key={"0"}     class ={"span0-c7eoPE"}      >
Global</span>
 <span key={"1"}     class ={"span1-c7eoPE"}      >
Doctors</span>
</div>
</div>
 <div key={"1"}     class ={"rectangle-47179-38rwxk"}    >
</div>
 <div key={"2"}     class ={"login-details-38rwxk"}    >
<div key={"0"}     class ={"hello-axe3dy"}      >
Hello </div>
 <div key={"1"}     class ={"mr-rakin-axe3dy"}      >
Mr.Rakin</div>
 <div key={"2"}     class ={"sathishrakinainqacom-axe3dy"}      >
Sathish.rakin@ainqa.com</div>
 <img key={"3"}     class ={"line-9-axe3dy"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801422"}  alt ={"line-9@1x.png"}    >
</img>
</div>
 <div key={"3"}     class ={"reports-38rwxk"}    >
<div key={"0"}     class ={"reports-XiX9S9"}      >
Reports</div>
 <img key={"1"}     class ={"download-7-XiX9S9"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801397"}  alt ={"download--7-@1x.png"}    >
</img>
</div>
 <div key={"4"}     class ={"patient-regn-38rwxk"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/patient_registration")
            }}
           class ={"patient-registration-03Eyjx roboto-medium-dove-gray-14px"}      >
Patient Registration</div>
 <img key={"1"}     class ={"rectangle-47175-03Eyjx"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801452"}  alt ={"rectangle-47175@1x.png"}    >
</img>
</div>
 <div key={"5"}     class ={"master-38rwxk"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/specialist_doctor")
            }}
           class ={"master-QjIt1f roboto-medium-dove-gray-14px"}      >
Master</div>
 <img key={"1"}     class ={"material-master-data-QjIt1f"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801432"}  alt ={"material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"}    >
</img>
</div>
 <div key={"6"}     class ={"group-93714-38rwxk"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/visit_registration")
            }}
           class ={"visit-registration-3JfVI6 roboto-medium-dove-gray-14px"}      >
Visit Registration</div>
 <img key={"1"}     class ={"download-6-3JfVI6"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183801390"}  alt ={"download--6-@1x.png"}    >
</img>
</div>
</div>
</div>
 <div key={"3"}     class ={"version-3zrh10"}    >
<div key={"0"}     class ={"v1001-3jxhjo"}      >
v1.0.01</div>
</div>
</div>
</div>

                </React.Fragment>}
            </NamespacesConsumer>
        }
} 

const mapStateToProps = (state) => ({
    Encounter_Full_ReadData:state?.ReportsEncounter_Full_Read_2opEWReducer?.Encounter_Full_Read_2opEW?.data,
});
  
export default connect(
    mapStateToProps,
    actions
)(withRouter(translate()(Reports)));