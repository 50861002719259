import React from 'react';
import { actions } from "real_wear_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer,translate } from 'react-i18next';
import qdmstyles  from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import './index.css';
import { languageOptions } from '../../utils'
import { LoggerContext } from '../../contexts'




import * as ALL from '../../components'

import { 
div, 
TextInput, 
Dropdown } from 'qdm-component-library';

class EditOnsiteDoctor extends React.Component{
        static contextType = LoggerContext
        constructor(props,context){
            super(props,context);
            this.state = {edit_onsite_doctor_zf8jz  : null,edit_onsite_doctor_vsyyz  : null,edit_onsite_doctor_mp0am  : null,edit_onsite_doctor_0q7fu  : null,edit_onsite_doctor_usujz  : null,edit_onsite_doctor_z9rqa  : null,edit_onsite_doctor_bpnn7  : null,edit_onsite_doctor_idoub  : null}
        }

        changeState = (key, value) => {
            this.setState({
                [key]:value
            });
        }
        
onchange_vsyyz  = (e) => {
                    
                    this.setState({ edit_onsite_doctor_vsyyz : e?.target?.value })
                }
onchange_mp0am  = (e) => {
                    
                    this.setState({ edit_onsite_doctor_mp0am : e })
                }
onchange_0q7fu  = (e) => {
                    
                    this.setState({ edit_onsite_doctor_0q7fu : e })
                }
onchange_usujz  = (e) => {
                    
                    this.setState({ edit_onsite_doctor_usujz : e?.target?.value })
                }
onchange_z9rqa  = (e) => {
                    
                    this.setState({ edit_onsite_doctor_z9rqa : e?.target?.value })
                }
onchange_bpnn7  = (e) => {
                    
                    this.setState({ edit_onsite_doctor_bpnn7 : e?.target?.value })
                }
onclick_idoub  =  async (event,data) => {const payload0 = {

                                            edit_onsite_doctor_history  :  this.state.edit_onsite_doctor_history ,

                                            edit_onsite_doctor_vsyyz  :  this.state.edit_onsite_doctor_vsyyz ,

                                            edit_onsite_doctor_mp0am  :  this.state.edit_onsite_doctor_mp0am ?.value,

                                            edit_onsite_doctor_0q7fu  :  this.state.edit_onsite_doctor_0q7fu ?.label,

                                            edit_onsite_doctor_bpnn7  :  this.state.edit_onsite_doctor_bpnn7 ,

                                            edit_onsite_doctor_z9rqa  :  this.state.edit_onsite_doctor_z9rqa ,

                                            edit_onsite_doctor_usujz  :  this.state.edit_onsite_doctor_usujz ,history : this.props.history.location.state,data : data}
let data0 = await this.props.Edit_Onsite_DoctorPractitioner_Update_Updated_IdOUB(payload0)
if(data0?.payload?.Code === 200){
                
                    window.location.href = "/onsite_doctor";
                
            }
                  
                
                }
        componentDidMount(){

             /// sample localstorage translation
             if(sessionStorage.defaultLng){
                const {i18n} = this.props;
                i18n.changeLanguage(sessionStorage.defaultLng)
            }
            
            
                        
                        const payload = { history : this.props.history.location.state }
                        this.props.Edit_Onsite_DoctorPractitioner_Onsight_Singleread_Updated_ZF8jz(payload)
                    
        }

        shouldComponentUpdate(props, state) {
            
        if(this.props?.Practitioner_Update_UpdatedData?.loading !== props?.Practitioner_Update_UpdatedData?.loading){
            if(!props?.Practitioner_Update_UpdatedData?.loading && props?.Practitioner_Update_UpdatedData.error){
                
            }else if(!props?.Practitioner_Update_UpdatedData?.loading && !props?.Practitioner_Update_UpdatedData.error){
                
        }
    }
        
            return true;
          }


        render(){

            
            const { PractitionerData,Practitioner_Onsight_Singleread_UpdatedData,Practitioner_Update_UpdatedData ,data } = this.props;
            

            

            return <NamespacesConsumer>
                {(t, { i18n }) => <React.Fragment    >
                    <div key={"0"}       >
<input key={"0"}     type ={"hidden"}  id ={"anPageName"}    value ={"edit-onsite-doctor"}    >
</input>
 <div key={"1"}     class ={"edit-onsite-doctor screen"}    >
<div key={"0"}     class ={"group-93711-cRzB4N"}    >
<div key={"0"}     class ={"rectangle-47173-rIQkV4"}    >
</div>
 <div key={"1"}     class ={"group-93715-rIQkV4"}    >
<div key={"0"}     class ={"logo-slvjiZ"}    >
<img key={"0"}     class ={"x51-client_profile_subscription-10-enxFdo"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183739820"}  alt ={"5-1-client-profile-subscription---10@1x.png"}    >
</img>
 <div key={"1"}     class ={"global-doctors-enxFdo"}    >
<span key={"0"}     class ={"span0-7RY40Z"}      >
Global</span>
 <span key={"1"}     class ={"span1-7RY40Z"}      >
Doctors</span>
</div>
</div>
 <div key={"1"}     class ={"login-details-slvjiZ"}    >
<div key={"0"}     class ={"hello-qSgli1"}      >
Hello </div>
 <div key={"1"}     class ={"mr-rakin-qSgli1"}      >
Mr.Rakin</div>
 <div key={"2"}     class ={"sathishrakinainqacom-qSgli1"}      >
Sathish.rakin@ainqa.com</div>
 <img key={"3"}     class ={"line-9-qSgli1"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744502"}  alt ={"line-9@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"reports-slvjiZ"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/reports")
            }}
           class ={"reports-lFxr1v roboto-medium-dove-gray-14px"}      >
Reports</div>
 <img key={"1"}     class ={"download-7-lFxr1v"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744506"}  alt ={"download--7-@1x.png"}    >
</img>
</div>
 <div key={"3"}     class ={"patient-regn-slvjiZ"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/patient_registration")
            }}
           class ={"patient-registration-kp6L8r roboto-medium-dove-gray-14px"}      >
Patient Registration</div>
 <img key={"1"}     class ={"rectangle-47175-kp6L8r"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744525"}  alt ={"rectangle-47175@1x.png"}    >
</img>
</div>
 <div key={"4"}     class ={"rectangle-47179-slvjiZ"}    >
</div>
 <div key={"5"}     class ={"master-slvjiZ"}    >
<div key={"0"}     class ={"master-bJA2fF"}      >
Master</div>
 <img key={"1"}     class ={"material-master-data-bJA2fF"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744545"}  alt ={"material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"}    >
</img>
</div>
 <div key={"6"}     class ={"group-93714-slvjiZ"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/visit_registration")
            }}
           class ={"visit-registration-cKNEBx roboto-medium-dove-gray-14px"}      >
Visit Registration</div>
 <img key={"1"}     class ={"download-6-cKNEBx"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744562"}  alt ={"download--6-@1x.png"}    >
</img>
</div>
</div>
</div>
 <div key={"1"}     class ={"version-cRzB4N"}    >
<div key={"0"}     class ={"v1001-wx79Te"}      >
v1.0.01</div>
</div>
 <div key={"2"}  
            onClick={() => { 
                
                this.props.history.push("/specialist_doctor")
            }}
           class ={"specialist-doctor-cRzB4N"}      >
Specialist Doctor</div>
 <div key={"3"}  
            onClick={() => { 
                
                this.props.history.push("/patient_facility")
            }}
           class ={"patient-facility-cRzB4N"}      >
Patient Facility</div>
 <div key={"4"}     class ={"onsite-doctor-cRzB4N"}    >
<div key={"0"}     class ={"onsite-doctor-medical-assistant-fNMvBN"}      >
Onsite Doctor/Medical Assistant</div>
 <img key={"1"}     class ={"rectangle-47181-fNMvBN"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183770632"}  alt ={"rectangle-47181@1x.png"}    >
</img>
</div>
 <div key={"5"}     class ={"group-93712-cRzB4N"}    >
<div key={"0"}     class ={"rectangle-47180-tEkdlU"}    >
</div>
 <div key={"1"}     class ={"login-avatar-tEkdlU"}    >
<div key={"0"}     class ={"rectangle-47177-OKekXy"}    >
</div>
 <img key={"1"}     class ={"rectangle-47178-OKekXy"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744508"}  alt ={"rectangle-47178@1x.png"}    >
</img>
 <div key={"2"}     class ={"rakin-OKekXy"}      >
RAKIN</div>
 <img key={"3"}     class ={"icon-awesome-chevron-down-OKekXy"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744504"}  alt ={"icon-awesome-chevron-down@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"master-tEkdlU"}      >
Master</div>
</div>
 <div key={"6"}     class ={"group-93704-cRzB4N"}    >
<div key={"0"}     class ={"rectangle-47165-9By8Qq"}    >
</div>
 <div key={"1"}     class ={"edit-onsite-doctor-medical-assistant-9By8Qq"}      >
Edit Onsite Doctor/Medical Assistant</div>
 <img key={"2"}     class ={"line-3-9By8Qq"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183770463"}  alt ={"line-3@1x.png"}    >
</img>
 <div key={"3"}     class ={"qdm-list-9By8Qq"}     style={ qdmstyles.ebOfc } >
{Array.isArray(Practitioner_Onsight_Singleread_UpdatedData?.['result']) && Practitioner_Onsight_Singleread_UpdatedData?.['result']?.map((data , index)=>{
                      return <React.Fragment key={index}><div key={"0"}     class ={"listitem-4STPMu"}    >
<div key={"0"}     class ={"qdm-textinput-z8K7jg"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_vsyyz (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Doctor Name")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}  value={this.state.edit_onsite_doctor_vsyyz ?? data?.Practitioner_Name}    >
</TextInput>
</div>
 <div key={"1"}     class ={"qdm-textinput-X3cWcD"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_usujz (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Email Id")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}  value={this.state.edit_onsite_doctor_usujz ?? data?.Practitioner_Mail_ID}    >
</TextInput>
</div>
 <div key={"2"}     class ={"qdm-textinput-Qwci3K"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_bpnn7 (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Address")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}  value={this.state.edit_onsite_doctor_bpnn7 ?? data?.Address}    >
</TextInput>
</div>
 <div key={"3"}     class ={"qdm-dropdown-z8K7jg"}    >
<Dropdown key={"0"} onChange = {(e) => this.onchange_0q7fu (e) }    elevation ={"1"}  fullWidth ={true}  dropDownTextSize ={"14"}  dropDownTextColor ={"#000"}  activeTextColor ={"#000"}  dropdownListMargin ={"0"}  dropdownListPadding ={"8"}  options ={[{"value":"Chennai","label":"Chennai"},{"value":"Coimbatore","label":"Coimbatore"},{"value":"Trichy","label":"Trichy"},{"value":"Madurai","label":"Madurai"},{"value":"Kochi","label":"Kochi"},{"value":"Kuala Lumpur","label":"Kuala Lumpur"}]}  label= {t("Location")}  isRequired ={true}  Url ={""}  keyName ={""}  valueName ={""}  value={this.state.edit_onsite_doctor_0q7fu ?? {label : data?.Location}}   style={ qdmstyles.QfU } >
</Dropdown>
</div>
 <div key={"4"}     class ={"qdm-dropdown-X3cWcD"}    >
<Dropdown key={"0"} onChange = {(e) => this.onchange_mp0am (e) }    elevation ={"1"}  fullWidth ={true}  dropDownTextSize ={"14"}  dropDownTextColor ={"#000"}  activeTextColor ={"#000"}  dropdownListMargin ={"0"}  dropdownListPadding ={"8"}  options ={[{"value":"Surgery","label":"Surgery"},{"value":"Pediatrics","label":"Pediatrics"},{"value":"Neurology","label":"Neurology"},{"value":"Radiology","label":"Radiology"}]}  label= {t("Speciality")}  isRequired ={true}  Url ={""}  keyName ={""}  valueName ={""}  value={this.state.edit_onsite_doctor_mp0am ?? {label : data?.Specialty_ID}}   style={ qdmstyles.mpam } >
</Dropdown>
</div>
 <div key={"5"}     class ={"qdm-textinput-bsB94u"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_z9rqa (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Mobile Number")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}  value={this.state.edit_onsite_doctor_z9rqa ?? data?.Phone_Number}    >
</TextInput>
</div>
</div>
</React.Fragment>
                  })}{data?.result}</div>
 <div key={"4"}  
            onClick={() => { 
                
                this.props.history.push("/onsite_doctor")
            }}
           class ={"group-93708-9By8Qq"}    >
<div key={"0"}     class ={"back-3mAaJr"}      >
Back</div>
 <img key={"1"}     class ={"path-734-3mAaJr"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183772612"}  alt ={"path-734@1x.png"}    >
</img>
</div>
 <div key={"5"}     class ={"qdm-button-9By8Qq"}    >
<div key={"0"} onClick = {(e) => this.onclick_idoub (e , data ) }    class ={"submit-P5EMta"}      >
Submit</div>
</div>
</div>
</div>
</div>

                </React.Fragment>}
            </NamespacesConsumer>
        }
} 

const mapStateToProps = (state) => ({
    Practitioner_Onsight_Singleread_UpdatedData:state?.Edit_Onsite_DoctorPractitioner_Onsight_Singleread_Updated_ZF8jzReducer?.Practitioner_Onsight_Singleread_Updated_ZF8jz?.data,Practitioner_Update_UpdatedData:state?.Edit_Onsite_DoctorPractitioner_Update_Updated_IdOUBReducer?.Practitioner_Update_Updated_IdOUB?.data,
});
  
export default connect(
    mapStateToProps,
    actions
)(withRouter(translate()(EditOnsiteDoctor)));