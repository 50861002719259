import React from 'react';
import { actions } from "real_wear_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer,translate } from 'react-i18next';
import qdmstyles  from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import './index.css';
import { languageOptions } from '../../utils'
import { LoggerContext } from '../../contexts'




import * as ALL from '../../components'

import { 
TextInput, 
Dropdown } from 'qdm-component-library';

class AddSpecialistDoctor extends React.Component{
        static contextType = LoggerContext
        constructor(props,context){
            super(props,context);
            this.state = {add_specialist_doctor_obmtw  : null,add_specialist_doctor_yhvxc  : null,add_specialist_doctor_9h6ec  : null,add_specialist_doctor_shksf  : null,add_specialist_doctor_naiin  : null,add_specialist_doctor_gfves  : null,add_specialist_doctor_psudy  : null}
        }

        changeState = (key, value) => {
            this.setState({
                [key]:value
            });
        }
        onchange_obmtw  = (e) => {
                    
                    this.setState({ add_specialist_doctor_obmtw : e?.target?.value })
                }
onchange_yhvxc  = (e) => {
                    
                    this.setState({ add_specialist_doctor_yhvxc : e })
                }
onchange_9h6ec  = (e) => {
                    
                    this.setState({ add_specialist_doctor_9h6ec : e })
                }
onchange_shksf  = (e) => {
                    
                    this.setState({ add_specialist_doctor_shksf : e?.target?.value })
                }
onchange_naiin  = (e) => {
                    
                    this.setState({ add_specialist_doctor_naiin : e?.target?.value })
                }
onchange_gfves  = (e) => {
                    
                    this.setState({ add_specialist_doctor_gfves : e?.target?.value })
                }
onclick_psudy  =  async (event,data) => {const payload0 = {

                                            add_specialist_doctor_obmtw  :  this.state.add_specialist_doctor_obmtw ,

                                            add_specialist_doctor_yhvxc  :  this.state.add_specialist_doctor_yhvxc ?.value,

                                            add_specialist_doctor_9h6ec  :  this.state.add_specialist_doctor_9h6ec ?.label,

                                            add_specialist_doctor_gfves  :  this.state.add_specialist_doctor_gfves ,

                                            add_specialist_doctor_naiin  :  this.state.add_specialist_doctor_naiin ,

                                            add_specialist_doctor_shksf  :  this.state.add_specialist_doctor_shksf ,history : this.props.history.location.state,data : data}
let data0 = await this.props.Add_Specialist_DoctorPractitioner_Specialist_insert_Updated_pSuDY(payload0)
if(data0?.payload?.Code === 200){
                
                    window.location.href = "/specialist_doctor";
                
            }
                  
                
                }
        componentDidMount(){

             /// sample localstorage translation
             if(sessionStorage.defaultLng){
                const {i18n} = this.props;
                i18n.changeLanguage(sessionStorage.defaultLng)
            }
            
            
        }

        shouldComponentUpdate(props, state) {
            
        if(this.props?.Practitioner_Specialist_insert_UpdatedData?.loading !== props?.Practitioner_Specialist_insert_UpdatedData?.loading){
            if(!props?.Practitioner_Specialist_insert_UpdatedData?.loading && props?.Practitioner_Specialist_insert_UpdatedData.error){
                
            }else if(!props?.Practitioner_Specialist_insert_UpdatedData?.loading && !props?.Practitioner_Specialist_insert_UpdatedData.error){
                
        }
    }
        
            return true;
          }


        render(){

            
            const { SpecialtyData,Practitioner_Specialist_insert_UpdatedData ,data } = this.props;
            

            

            return <NamespacesConsumer>
                {(t, { i18n }) => <React.Fragment    >
                    <div key={"0"}       >
<input key={"0"}     type ={"hidden"}  id ={"anPageName"}    value ={"add-specialist-doctor"}    >
</input>
 <div key={"1"}     class ={"add-specialist-doctor screen"}    >
<div key={"0"}     class ={"group-93704-B47n0V"}    >
<div key={"0"}     class ={"rectangle-47165-xPtKvX"}    >
</div>
 <div key={"1"}     class ={"add-specialist-doctor-xPtKvX"}      >
Add Specialist Doctor</div>
 <img key={"2"}     class ={"line-3-xPtKvX"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183770463"}  alt ={"line-3@1x.png"}    >
</img>
 <div key={"3"}     class ={"qdm-textinput-xPtKvX"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_obmtw (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("EMP No")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"0"}    >
</TextInput>
</div>
 <div key={"4"}     class ={"qdm-textinput-rC6gw6"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_shksf (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Email Id")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
 <div key={"5"}     class ={"qdm-textinput-acJ7E3"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_gfves (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Address")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
 <div key={"6"}     class ={"qdm-dropdown-xPtKvX"}    >
<Dropdown key={"0"} onChange = {(e) => this.onchange_9h6ec (e) }    elevation ={"1"}  fullWidth ={true}  dropDownTextSize ={"14"}  dropDownTextColor ={"#000"}  activeTextColor ={"#000"}  dropdownListMargin ={"0"}  dropdownListPadding ={"8"}  options ={[{"value":"Chennai","label":"Chennai"},{"value":"Coimbatore","label":"Coimbatore"},{"value":"Madurai","label":"Madurai"},{"value":"Trichy","label":"Trichy"},{"value":"Kochi","label":"Kochi"},{"value":"Kuala Lumpur","label":"Kuala Lumpur"}]}  label= {t("Location")}  isRequired ={true}  Url ={""}  keyName ={""}  valueName ={""}   style={ qdmstyles.HEC } >
</Dropdown>
</div>
 <div key={"7"}     class ={"qdm-dropdown-rC6gw6"}    >
<Dropdown key={"0"} onChange = {(e) => this.onchange_yhvxc (e) }    elevation ={"1"}  fullWidth ={true}  dropDownTextSize ={"14"}  dropDownTextColor ={"#000"}  activeTextColor ={"#000"}  dropdownListMargin ={"0"}  dropdownListPadding ={"8"}  options ={[{"value":"Surgery","label":"Surgery"},{"value":"Pediatrics","label":"Pediatrics"},{"value":"Neurology","label":"Neurology"},{"value":"Radiology","label":"Radiology"}]}  label= {t("Speciality")}  isRequired ={true}  Url ={""}  keyName ={""}  valueName ={""}   style={ qdmstyles.YHvxc } >
</Dropdown>
</div>
 <div key={"8"}     class ={"qdm-textinput-4sjKsh"}    >
<TextInput key={"0"} onChange = {(e) => this.onchange_naiin (e) }    type ={"text"}  name= {t("text")}  variant ={"outlined"}  helperTextType ={"error"}  label= {t("Mobile Number")}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={""}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
 <div key={"9"}     class ={"group-93708-xPtKvX"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/specialist_doctor")
            }}
           class ={"back-da45DN"}      >
Back</div>
 <img key={"1"}     class ={"path-734-da45DN"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183772612"}  alt ={"path-734@1x.png"}    >
</img>
</div>
 <div key={"10"}     class ={"qdm-button-xPtKvX"}    >
<div key={"0"} onClick = {(e) => this.onclick_psudy (e) }    class ={"submit-UYmVae"}      >
Submit</div>
</div>
</div>
 <div key={"1"}     class ={"group-93711-B47n0V"}    >
<div key={"0"}     class ={"rectangle-47173-Eqnjc7"}    >
</div>
 <div key={"1"}     class ={"group-93715-Eqnjc7"}    >
<div key={"0"}     class ={"logo-f4xRiC"}    >
<img key={"0"}     class ={"x51-client_profile_subscription-10-75rJ9e"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183739820"}  alt ={"5-1-client-profile-subscription---10@1x.png"}    >
</img>
 <div key={"1"}     class ={"global-doctors-75rJ9e"}    >
<span key={"0"}     class ={"span0-YH2Lpv"}      >
Global</span>
 <span key={"1"}     class ={"span1-YH2Lpv"}      >
Doctors</span>
</div>
</div>
 <div key={"1"}     class ={"login-details-f4xRiC"}    >
<div key={"0"}     class ={"hello-MpNhwp"}      >
Hello </div>
 <div key={"1"}     class ={"mr-rakin-MpNhwp"}      >
Mr.Rakin</div>
 <div key={"2"}     class ={"sathishrakinainqacom-MpNhwp"}      >
Sathish.rakin@ainqa.com</div>
 <img key={"3"}     class ={"line-9-MpNhwp"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744502"}  alt ={"line-9@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"reports-f4xRiC"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/reports")
            }}
           class ={"reports-PRhm9N roboto-medium-dove-gray-14px"}      >
Reports</div>
 <img key={"1"}     class ={"download-7-PRhm9N"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744506"}  alt ={"download--7-@1x.png"}    >
</img>
</div>
 <div key={"3"}     class ={"patient-regn-f4xRiC"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/patient_registration")
            }}
           class ={"patient-registration-66xEsl roboto-medium-dove-gray-14px"}      >
Patient Registration</div>
 <img key={"1"}     class ={"rectangle-47175-66xEsl"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744525"}  alt ={"rectangle-47175@1x.png"}    >
</img>
</div>
 <div key={"4"}     class ={"rectangle-47179-f4xRiC"}    >
</div>
 <div key={"5"}     class ={"master-f4xRiC"}    >
<div key={"0"}     class ={"master-l5DuEx"}      >
Master</div>
 <img key={"1"}     class ={"material-master-data-l5DuEx"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744545"}  alt ={"material-master-data-master-people-icon-with-png-and-vector-6909@1x.png"}    >
</img>
</div>
 <div key={"6"}     class ={"group-93714-f4xRiC"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/visit_registration")
            }}
           class ={"visit-registration-MjGdxM roboto-medium-dove-gray-14px"}      >
Visit Registration</div>
 <img key={"1"}     class ={"download-6-MjGdxM"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744562"}  alt ={"download--6-@1x.png"}    >
</img>
</div>
</div>
</div>
 <div key={"2"}     class ={"version-B47n0V"}    >
<div key={"0"}     class ={"v1001-uvQXEm"}      >
v1.0.01</div>
</div>
 <img key={"3"}     class ={"line-8-B47n0V"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183770470"}  alt ={"line-8@1x.png"}    >
</img>
 <div key={"4"}     class ={"onsite-doctor-medical-assistant-B47n0V"}      >
Onsite Doctor/Medical Assistant</div>
 <div key={"5"}     class ={"specialist-doctors-B47n0V"}    >
<div key={"0"}     class ={"specialist-doctor-r7B79V"}      >
Specialist Doctor</div>
 <img key={"1"}     class ={"rectangle-43868-r7B79V"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183770484"}  alt ={"rectangle-43868@1x.png"}    >
</img>
</div>
 <div key={"6"}  
            onClick={() => { 
                
                this.props.history.push("/patient_facility")
            }}
           class ={"patient-facility-B47n0V"}      >
Patient Facility</div>
 <div key={"7"}     class ={"group-93712-B47n0V"}    >
<div key={"0"}     class ={"rectangle-47180-iZeJ8x"}    >
</div>
 <div key={"1"}     class ={"login-avatar-iZeJ8x"}    >
<div key={"0"}     class ={"rectangle-47177-xqwBnA"}    >
</div>
 <img key={"1"}     class ={"rectangle-47178-xqwBnA"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744508"}  alt ={"rectangle-47178@1x.png"}    >
</img>
 <div key={"2"}     class ={"rakin-xqwBnA"}      >
RAKIN</div>
 <img key={"3"}     class ={"icon-awesome-chevron-down-xqwBnA"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183744504"}  alt ={"icon-awesome-chevron-down@1x.png"}    >
</img>
</div>
 <div key={"2"}     class ={"master-iZeJ8x"}      >
Master</div>
</div>
</div>
</div>

                </React.Fragment>}
            </NamespacesConsumer>
        }
} 

const mapStateToProps = (state) => ({
    Practitioner_Specialist_insert_UpdatedData:state?.Add_Specialist_DoctorPractitioner_Specialist_insert_Updated_pSuDYReducer?.Practitioner_Specialist_insert_Updated_pSuDY?.data,
});
  
export default connect(
    mapStateToProps,
    actions
)(withRouter(translate()(AddSpecialistDoctor)));