const Routes = {
  "login": "/login",
  "patientRegistration": "/patient_registration",
  "visitRegistration": "/visit_registration",
  "specialistDoctor": "/specialist_doctor",
  "onsiteDoctor": "/onsite_doctor",
  "patientFacility": "/patient_facility",
  "addSpecialistDoctor": "/add_specialist_doctor",
  "editSpecialistDoctor": "/edit_specialist_doctor",
  "addOnsiteDoctor": "/add_onsite_doctor",
  "editOnsiteDoctor": "/edit_onsite_doctor",
  "addPatientFacility": "/add_patient_facility",
  "reports": "/reports"
};
          
export { Routes };