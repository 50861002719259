import React from 'react';
import { actions } from "real_wear_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer,translate } from 'react-i18next';
import qdmstyles  from "./styles.json";
import { withRouter } from "react-router-dom";
import { Modal } from "qdm-component-library";
import './index.css';
import { languageOptions } from '../../utils'
import { LoggerContext } from '../../contexts'




import * as ALL from '../../components'

import { 
TextInput } from 'qdm-component-library';

class Login extends React.Component{
        static contextType = LoggerContext
        constructor(props,context){
            super(props,context);
            this.state = {}
        }

        changeState = (key, value) => {
            this.setState({
                [key]:value
            });
        }
        
        componentDidMount(){

             /// sample localstorage translation
             if(sessionStorage.defaultLng){
                const {i18n} = this.props;
                i18n.changeLanguage(sessionStorage.defaultLng)
            }
            
            
        }

        shouldComponentUpdate(props, state) {
            
            return true;
          }


        render(){

            

            

            return <NamespacesConsumer>
                {(t, { i18n }) => <React.Fragment    >
                    <div key={"0"}       >
<input key={"0"}     type ={"hidden"}  id ={"anPageName"}    value ={"login"}    >
</input>
 <div key={"1"}     class ={"login screen"}    >
<img key={"0"}     class ={"path-93227-b8DrbD"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183739841"}  alt ={"path-93227@1x.png"}    >
</img>
 <div key={"1"}     class ={"group-93715-b8DrbD"}    >
<div key={"0"}     class ={"welcome-bgeoTh"}      >
Welcome</div>
 <div key={"1"}     class ={"qdm-textinput-bgeoTh"}    >
<TextInput key={"0"}     type ={"text"}  name ={"text"}  variant ={"outlined"}  helperTextType ={"error"}  label ={""}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={"Username"}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={""}    >
</TextInput>
</div>
 <div key={"2"}     class ={"qdm-textinput-Cxvxcm"}    >
<TextInput key={"0"}     type ={"text"}  name ={"text"}  variant ={"outlined"}  helperTextType ={"error"}  label ={""}  autoFocus ={false}  autoComplete ={"off"}  search ={false}  borderColor ={"#9b9b9b"}  elevation ={"0"}  placeholder ={"Password"}  size ={"medium"}  isRequired ={true}  hoverColor ={"#000"}  dateIcon ={""}  maxLength ={"100"}  iconText ={""}  minLength ={"100"}    >
</TextInput>
</div>
 <div key={"3"}     class ={"qdm-button-bgeoTh"}    >
<div key={"0"}  
            onClick={() => { 
                
                this.props.history.push("/patient_registration")
            }}
           class ={"sign-in-qwfgee"}      >
Sign In</div>
</div>
 <div key={"4"}     class ={"forgot-password-bgeoTh"}      >
Forgot Password ?</div>
 <div key={"5"}     class ={"sign-in-to-your-acco-bgeoTh"}      >
Sign-in to your account using your login username and password</div>
 <div key={"6"}     class ={"group-93714-bgeoTh"}    >
<div key={"0"}     class ={"remember-me-xa4FNl"}      >
Remember me</div>
 <div key={"1"}     class ={"rectangle-47172-xa4FNl"}    >
</div>
</div>
</div>
 <div key={"2"}     class ={"group-93723-b8DrbD"}    >
<img key={"0"}     class ={"undraw_medicine_b1ol-qr1pd2"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183739850"}  alt ={"undraw-medicine-b1ol@1x.png"}    >
</img>
</div>
 <div key={"3"}     class ={"group-93713-b8DrbD"}    >
<img key={"0"}     class ={"x51-client_profile_subscription-10-h0zH23"}  src ={"https://fileupload.dev.ainqaplatform.in/QDM_Metadata/183739820"}  alt ={"5-1-client-profile-subscription---10@1x.png"}    >
</img>
 <div key={"1"}     class ={"global-doctors-h0zH23"}    >
<span key={"0"}     class ={"span0-3GZPF5"}      >
Global</span>
 <span key={"1"}     class ={"span1-3GZPF5"}      >
Doctors</span>
</div>
</div>
</div>
</div>

                </React.Fragment>}
            </NamespacesConsumer>
        }
} 

const mapStateToProps = (state) => ({
    
});
  
export default connect(
    mapStateToProps,
    actions
)(withRouter(translate()(Login)));